import * as React from "react"
import { graphql, Link, navigate } from "gatsby"
import { useLocation } from "@reach/router"

import Footer from "../components/Footer"
import { Container, Section } from "../components/global"
import Grid from "../components/Grid"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Card from "../components/Card"
import SEOHead from "../components/SEOHead"

import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import styled from "styled-components"
import FigCaption from "../components/FigCaption"
import { formatDate } from "../utils"
import moment from "dayjs"
import LazyList from "@vinayakakv/react-lazy-list"

const PhotoOverlay = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 60px 0 20px 0;
  background: var(--color-accent);
  z-index: 3;
`

const PhotoContent = styled(DialogContent)`
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 0.2fr;
  gap: 1em;
  align-content: center;
  align-items: center;
  color: inherit;
  justify-items: center;
`

const PhotoDescription = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  & * {
    margin: 0;
  }
  max-width: 1200px;
  width: 100%;
  padding: 0 16px;
`

const PhotoWrapper = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
`

const PanoramaWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  img {
    height: 100%;
    display: block;
    margin: auto;
  }
`

const NavigationButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  padding: 0 16px;
`

function OverlayContent({ currentPhoto }) {
  const { src, type, caption, blogPost } = currentPhoto
  return (
    <>
      {type === "PANORAMA" ? (
        <PanoramaWrapper>
          <img src={getSrc(src.localFile)} alt={caption} />
        </PanoramaWrapper>
      ) : (
        <PhotoWrapper>
          <GatsbyImage
            image={getImage(src.localFile)}
            alt={caption}
            objectFit="contain"
            style={{ height: "100%" }}
          />
        </PhotoWrapper>
      )}
      <PhotoDescription>
        <FigCaption>{caption}</FigCaption>
        <p>
          Source:{" "}
          {blogPost && (
            <Link to={`/writings/${blogPost.slug}`}>{blogPost.title}</Link>
          )}
        </p>
        <p>Posted On: {blogPost && formatDate(blogPost.date)}</p>
      </PhotoDescription>
    </>
  )
}

export default function Photography({
  data: {
    allGraphCmsPhoto: { nodes },
  },
}) {
  const getDate = node => node.blogPost?.date
  const idToIndex = nodes.reduce(
    (mapping, photo, index) => ({ ...mapping, [photo.id]: index }),
    {}
  )
  const photos = nodes
    .sort((first, second) =>
      moment(getDate(second)).diff(moment(getDate(first)))
    )
    .map((photo, index, self) => ({
      ...photo,
      previousId: index !== 0 ? self[index - 1].id : null,
      nextId: index < self.length - 1 ? self[index + 1].id : null,
    }))
  const { search } = useLocation()
  const currentPhoto =
    photos[idToIndex[new URLSearchParams(search).get("id")]] ?? null
  const closeDialog = () => {
    navigate(-1)
  }
  const forward = () => {
    navigate(`?id=${currentPhoto.nextId}`, { replace: true })
  }
  const backward = () => {
    navigate(`?id=${currentPhoto.previousId}`, { replace: true })
  }
  const isOpen = currentPhoto !== null
  return (
    <Layout>
      <SEOHead
        title="Photography"
        description="Photos captured by Vinayaka K V"
        image={getSrc(photos[0].src.localFile)}
      />
      <Navbar />
      <Container>
        <Section>
          <h1>Photography</h1>
          <LazyList
            list={photos}
            initialSize={10}
            increment={5}
            renderer={image => (
              <Card
                key={image.id}
                onClick={() => {
                  navigate(`?id=${image.id}`)
                }}
                style={{
                  "--card-height": "calc(max(8rem, 20vw))",
                  "--gap": "0rem",
                  cursor: "pointer",
                }}
              >
                <GatsbyImage
                  image={getImage(image.src.localFile)}
                  alt={image.caption}
                  objectFit="cover"
                />
              </Card>
            )}
            wrapper={({ children }) => (
              <Grid
                style={{
                  "--auto-grid-min-size": "calc(max(8rem, 20vw))",
                  "--gap": "0.2rem",
                }}
              >
                {children}
              </Grid>
            )}
          />
        </Section>
      </Container>
      <Footer />
      <PhotoOverlay
        isOpen={isOpen}
        onDismiss={closeDialog}
        onKeyDown={({ key }) => {
          if (!isOpen) return
          if (
            currentPhoto?.nextId &&
            (key === "ArrowRight" || key === "ArrowUp")
          )
            forward()
          if (
            currentPhoto?.previousId &&
            (key === "ArrowLeft" || key === "ArrowDown")
          )
            backward()
        }}
      >
        <PhotoContent aria-label="Photo Content">
          {isOpen && (
            <>
              <OverlayContent currentPhoto={currentPhoto} />
              <NavigationButtons>
                <button
                  onClick={backward}
                  disabled={currentPhoto?.previousId === null}
                >
                  Prev
                </button>
                <button onClick={closeDialog}>Close</button>
                <button
                  onClick={forward}
                  disabled={currentPhoto?.nextId === null}
                >
                  Next
                </button>
              </NavigationButtons>
            </>
          )}
        </PhotoContent>
      </PhotoOverlay>
    </Layout>
  )
}

export const query = graphql`
  {
    allGraphCmsPhoto(filter: { showInGallery: { eq: true } }) {
      nodes {
        id: remoteId
        caption
        blogPost {
          title
          slug
          date
        }
        type
        src {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
